<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">以“球”会友，勇往直前</div>
          <div class="notice-time">
            <div>发布时间： 2021/12/22 17:48:31</div>
            <div>浏览： 127次</div>
          </div>
          <p>
            为丰富广大员工的文体娱乐生活，增强团队协作精神和公司凝聚力，公司开展了多项友谊联赛。
          </p>
          <p style="margin-top: 15px;">
            “小小银球连四海，诚伴友谊走天涯”，精彩的乒乓球比赛，吸引了众多同事驻足观看。有的选手之间恰好棋逢对手，不分上下。白色的小球在空中飞来飞去，别有一番风景！经过一番激烈角逐，最终由整机王威民、宋督、余翔包揽此次比赛前三名。
          </p>
          <div class="notice-img-1">
            <img src="./1.png" srcset="./1@2x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <img src="./2.png" srcset="./2@2x.png 2x" alt="">
          </div>
          <p>
            如果说乒乓球比赛需要的是快速的反应，那么台球是需要沉稳的性格和精心的布局。握杆、架桥、出杆、击球，每一个动作都彰显出台球健儿们的优良品性和非凡智慧。24位选手经过数日对决，最终整机何强、徐鲁军、祝瑞华获得本次比赛的第一、二、三名。
          </p>
          <div class="notice-img-2">
            <img src="./3.png" srcset="./3@2x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <img src="./4.png" srcset="./4@2x.png 2x" alt="">
          </div>
          <p style="margin-top: 15px;">
            “贴身对抗各投篮，夺步争球战正酣”每一个绝妙传球，每一个精准投球，都引起现场观众一阵阵喝彩声与掌声。无不让比赛推向一个又一个高潮；快攻，妙传，更是令比赛精彩纷呈……
          </p>
          <div class="notice-img-2">
            <img src="./5.png" srcset="./5@2x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <img src="./6.png" srcset="./6@2x.png 2x" alt="">
          </div>

          <p style="margin-top: 15px;">
            六支篮球队伍通过十五场激烈的角逐，整机团队以不败的战绩获得冠军，供应链团队和精密团队分别获得了亚军，季军。

          </p>
          <p style="margin-top: 15px;">
            通过丰富多彩的体育活动，不仅让全体员工能够在愉快的比赛活动中增进情谊、展示魅力，而且加强了员工锻炼身体和团队合作的意识，为公司的发展营造了一个自信、阳光、充满朝气和拼搏的企业氛围。
          </p>
          <p style="margin-top: 15px;">
            最后公司经营层，李云星副总，徐龙忠总经理总结讲话。对研发及市场取得的成绩给与肯定，对新的一年做好战略规划，激励我们坚定信心，逆流前行，圆满完成2022年经营目标。不断突破，传承，创新！
          </p>
          <div class="notice-footer">
            <div class="notice-prev">
              <router-link tag="span" to="/notice7">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">
              <router-link tag="span" to="/notice5">下一篇 ></router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年,……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">小鸟为天空而歌，蜜蜂为花朵而歌，今天……</router-link>
            </div>
            <div class="notice-other-time-2">2022-05-01 10:39</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">为助力健康亚运建设，保障劳动者职业健……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-26 16:52</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">2022年3月24日，杭州里德通信有限公司桐……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-25 11:26</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1,
.notice-img-2 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 64px;
  border-top: 1px solid #d4d4d4;
}

.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-next {
  color: #da261d;
  cursor: pointer;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
